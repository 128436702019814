import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import type { QueryResult } from "react-query";
import { useQuery } from "react-query";
import type { ErrorResponse } from "types";
import type { ActivityGroupWithPastActivityIds } from "types/model/activity-group";
import { handleRequestError } from "utils/handleRequestError";

export const getActivityGroup = async (
  _: string,
  id: string,
  publicView: boolean
): Promise<ActivityGroupWithPastActivityIds> => {
  const response: AxiosResponse<ActivityGroupWithPastActivityIds> =
    await axios.get(`/api/activity-groups/${id}${publicView ? "/public" : ""}`);

  return response.data;
};

export default function useActivityGroup(
  id: string,
  publicView = false
): QueryResult<ActivityGroupWithPastActivityIds, AxiosError<ErrorResponse>> {
  return useQuery(["activity-group", id, publicView], getActivityGroup, {
    retry: (failureCount: number, error) => {
      return error.response?.status === 404 ? false : failureCount < 3;
    },
    onError: handleRequestError,
    enabled: !!id && id !== "undefined"
  });
}
