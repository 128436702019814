import { Screen } from "types/general";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDoesObjectHaveValue = <T extends Record<string, any>>(
  obj: T
): boolean => {
  if (!obj) {
    return false;
  }
  return Object.values(obj).some(value => {
    return value !== null && value !== undefined;
  });
};

export const getScreenFromWidth = (width: number): Screen => {
  if (width < 640) {
    return Screen.XS;
  } else if (width < 768) {
    return Screen.SM;
  } else if (width < 1024) {
    return Screen.MD;
  } else if (width < 1280) {
    return Screen.LG;
  } else if (width < 1536) {
    return Screen.XL;
  } else {
    return Screen["2XL"];
  }
};
