import { useRef } from "react";
import { cn } from "utils/cn";
import { compareAsc } from "date-fns";
import { Dialog } from "@headlessui/react";
import type { Activity, Ticket } from "types/model/activity";
import type { SalesData } from "types/model/activity-group";
import { renderActivityDateString } from "helpers/helpers";
import type { Client } from "types/model/client";

interface ActivityTicketAddNewProps {
  ticket: Ticket;
  activities: Activity<string>[];
  salesData: SalesData;
  client: Client;
  onClose: () => void;
}

const SingleSessionTicketSales = ({
  ticket,
  activities,
  salesData,
  client,
  onClose
}: ActivityTicketAddNewProps): React.ReactElement => {
  const contentEl = useRef(null);
  return (
    <Dialog
      open={true}
      onClose={onClose}
      initialFocus={contentEl}
      className="fixed inset-0 z-50 flex items-center justify-center px-2 pb-4 sm:px-4"
    >
      <Dialog.Overlay className="absolute inset-0 bg-gray-500 opacity-75" />

      <div
        className="relative flex w-full max-w-lg flex-col overflow-y-auto rounded-lg bg-white px-8 py-6 shadow-xl lg:max-w-3xl"
        style={{ maxHeight: "82%" }}
        ref={contentEl}
      >
        <div className="sm:flex sm:items-start">
          <div className="mb-4 mt-3 w-full sm:mt-1">
            <h3 className="mb-4 text-lg font-medium leading-6 text-gray-900">
              {ticket.name} ticket sales {!ticket.enabled && " (Disabled)"}
            </h3>

            <dl>
              <div className="hidden sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dd className="text-sm font-medium text-gray-500">Date</dd>
                <dt className="text-sm font-medium text-gray-500">Time</dt>
                <dt className="text-sm font-medium text-gray-500">
                  Ticket sales
                </dt>
              </div>
              {activities
                .sort((a, b) =>
                  compareAsc(new Date(a.date.start), new Date(b.date.start))
                )
                .map((item, i) => {
                  return (
                    <div
                      key={item._id}
                      className={cn(
                        "sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:py-5",
                        i > 0 && "mt-8"
                      )}
                    >
                      <dd
                        className={cn(
                          "text-sm font-medium text-gray-500",
                          !item.enabled && "text-opacity-50"
                        )}
                      >
                        {renderActivityDateString({
                          activityDate: item.date,
                          dateOnly: true,
                          timeOnly: false,
                          timeZone: client.timeZone
                        })}
                      </dd>
                      <dt
                        className={cn(
                          "text-sm text-gray-900",
                          !item.enabled && "text-opacity-50"
                        )}
                      >
                        <span className="sm:hidden">Time: </span>
                        {renderActivityDateString({
                          activityDate: item.date,
                          dateOnly: false,
                          timeOnly: true,
                          timeZone: client.timeZone
                        })}
                      </dt>
                      <dt
                        className={cn(
                          "text-sm text-gray-900",
                          !item.enabled && "text-opacity-50"
                        )}
                      >
                        <span className="sm:hidden">Ticket sales: </span>
                        {salesData?.[item._id] || 0}
                      </dt>
                    </div>
                  );
                })}
            </dl>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SingleSessionTicketSales;
