import type { Client } from "types/model/client";
import { getName } from "country-list";
import type { Address } from "types/general";
import type { ActivityGroup } from "types/model/activity-group";

const statesListUS = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District of Columbia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" }
];

const statesListAU = [
  { value: "ACT", label: "Australian Capital Territory" },
  { value: "NSW", label: "New South Wales" },
  { value: "NT", label: "Northern Territory" },
  { value: "QLD", label: "Queensland" },
  { value: "SA", label: "South Australia" },
  { value: "TAS", label: "Tasmania" },
  { value: "VIC", label: "Victoria" },
  { value: "WA", label: "Western Australia" }
];

// Don't think we currently use this function
export const getCountryName = (client: Client) => {
  const countryCode = client.country || "GB";
  const countryName = getName(countryCode);
  return countryName;
};

export const generateGoogleMapsLink = (
  address: Address,
  client: Client,
  placeName?: string
) => {
  const baseURL = "https://www.google.com/maps/search/?api=1";

  const countryISO = client.country || "GB";

  // Construct the query string by concatenating the available fields
  let queryString = "";
  if (placeName) queryString += placeName + ", ";
  if (address.address1) queryString += address.address1 + ", ";
  if (address.address2) queryString += address.address2 + ", ";
  if (address.city) queryString += address.city + ", ";
  if (address.state) queryString += address.state + ", ";
  if (address.postalCode) queryString += address.postalCode + ", ";
  queryString += countryISO;

  // Trim any trailing comma and space, then URL encode
  queryString = encodeURIComponent(queryString.trim().replace(/,\s*$/, ""));

  return `${baseURL}&query=${queryString}`;
};

export const getStatesList = (country: string) => {
  if (country === "US") {
    return statesListUS;
  } else if (country === "AU") {
    return statesListAU;
  }
  return [];
};

export const getStatePostalCodeString = (address: Address) => {
  const { state, postalCode } = address;
  if (state && postalCode) {
    return `${state} ${postalCode}`;
  } else if (state) {
    return state;
  } else if (postalCode) {
    return postalCode;
  } else {
    return "";
  }
};

export const getAddressAsString = (address: Address): string => {
  const addressParts: string[] = [];

  if (address.address1) addressParts.push(address.address1);
  if (address.address2) addressParts.push(address.address2);
  if (address.city) addressParts.push(address.city);

  // Handle the combined state and postalCode case
  if (address.state && address.postalCode) {
    addressParts.push(`${address.state} ${address.postalCode}`);
  } else {
    if (address.state) addressParts.push(address.state);
    if (address.postalCode) addressParts.push(address.postalCode);
  }

  return addressParts.join(", ");
};

export const getActivityVenueAddress = (
  activityGroup: ActivityGroup
): string | undefined => {
  const fieldKey = "activity_venue";
  const venueFieldDataItem = activityGroup.fieldData.find(
    item => item.field?.internalKey === fieldKey
  );

  if (!venueFieldDataItem?.valueRefVenue?.address) {
    return undefined;
  }

  return getAddressAsString(venueFieldDataItem.valueRefVenue.address);
};
