export interface IdsHash {
  [key: string]: boolean;
}

export interface Hash<T = boolean> {
  [key: string]: T;
}

export interface Address {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}

export enum Screen {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl",
  "2XL" = "2xl"
}
