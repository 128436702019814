import axios from "axios";
import type { QueryResult } from "react-query";
import { useQuery } from "react-query";
import type { Field } from "types/model/field";

export const getFields = async (
  _: unknown,
  usage: string,
  includeDisabled?: boolean,
  includeAdminOnly?: boolean
): Promise<Field[]> => {
  const { data } = await axios.get(
    `/api/fields?usage=${usage}${
      includeDisabled ? "&includeDisabled=true" : ""
    }${includeAdminOnly ? "&includeAdminOnly=true" : ""}`
  );

  return data;
};

export default function useFields(
  usage: string,
  includeDisabled?: boolean,
  includeAdminOnly?: boolean
): QueryResult<Field[]> {
  return useQuery(
    ["fields", usage, includeDisabled, includeAdminOnly],
    getFields
  );
}
