import type { Client } from "types/model/client";

export const getCanClientSendAdminCommunication = (client: Client): boolean => {
  return (
    client.hasActiveSubscription ||
    client.allowAdminCommunicationInTrial ||
    client.subscriptionNotRequired
  );
};

export const getIsClientStandardPlanOrHigher = (client: Client): boolean => {
  return client.planLevel >= 20;
};

export const getIsClientPremiumPlanOrHigher = (client: Client): boolean => {
  return client.planLevel >= 30;
};

export const getClientMaxAdminUsers = (client: Client): number => {
  if (getIsClientPremiumPlanOrHigher(client)) {
    return Infinity;
  } else if (getIsClientStandardPlanOrHigher(client)) {
    return 5;
  }
  return 2;
};
