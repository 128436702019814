import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import { getShouldNotRetry } from "helpers/auth";
import type { QueryResult } from "react-query";
import { useQuery } from "react-query";
import type { ErrorResponse } from "types";
import type { ActivityGroupSalesData } from "types/model/activity-group";
import { handleRequestError } from "utils/handleRequestError";

const getActivityGroupSales = async (
  _: string,
  id: string,
  includeCancelled: boolean,
  includeWaitlistEntries: boolean
): Promise<ActivityGroupSalesData> => {
  let path = "sales";

  if (includeCancelled) {
    path = "sales-including-cancelled";
  } else if (includeWaitlistEntries) {
    path = "sales-including-waitlist-entries";
  }

  const response: AxiosResponse<ActivityGroupSalesData> = await axios.get(
    `/api/activity-groups/${id}/${path}`
  );

  return response.data;
};

export default function useActivityGroupSales(
  id: string,
  includeCancelled = false,
  includeWaitlistEntries = false
): QueryResult<ActivityGroupSalesData, AxiosError<ErrorResponse>> {
  return useQuery(
    ["activity-group-sales", id, includeCancelled, includeWaitlistEntries],
    getActivityGroupSales,
    {
      retry: (failureCount: number, error) => {
        return getShouldNotRetry(error.response?.status)
          ? false
          : failureCount < 3;
      },
      onError: handleRequestError,
      enabled: !!id
    }
  );
}
