import { forwardRef } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Image from "next/image";

interface SpotlightHelpInfoProps {
  width?: number;
  left?: number;
  helpStepIndex?: number;
  totalSteps?: number;
  children: React.ReactNode;
  arrowDirection: "left" | "up";
  setHelpStepIndex?: (index: number) => void;
  handleClose: () => void;
}

const randomId = Math.random().toString(36).substring(7);

const SpotlightHelpInfo = (
  {
    width = 264,
    left,
    totalSteps = 1,
    helpStepIndex,
    children,
    arrowDirection,
    setHelpStepIndex,
    handleClose
  }: SpotlightHelpInfoProps,
  ref: React.RefObject<HTMLDivElement>
): React.ReactElement => {
  return (
    <>
      {arrowDirection === "up" && (
        <style>
          {`
        .arrow_box:after {
          bottom: 100%;
          left: 50%;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: #ffffff;
          border-width: 8px;
          margin-left: -8px;
        }
      `}
        </style>
      )}
      {arrowDirection === "left" && (
        <style>
          {`
        #${randomId}:after {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(255, 255, 255, 0);
          border-right-color: #ffffff;
          border-width: 8px;
          margin-top: -8px;
        }
      `}
        </style>
      )}
      <div
        className="pointer-events-auto absolute z-50 w-full max-w-xs rounded-lg bg-white"
        style={{
          maxWidth: width,
          ...(left && { left })
        }}
        ref={ref}
      >
        <div id={randomId} className="arrow_box relative p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0 pt-0.5">
              <Image
                className="h-10 w-10 rounded-full"
                src="/images/ed.png"
                alt="Ed From Pembee"
                width={40}
                height={40}
              />
            </div>
            <div className="ml-3 w-0 flex-1">
              <p className="text-sm font-medium text-gray-900">
                Ed from Pembee
              </p>
              <div className="mt-1 text-sm text-gray-500">{children}</div>
              <div className="flex gap-5">
                {helpStepIndex < totalSteps - 1 && (
                  <a
                    className="mt-2 cursor-pointer text-sm font-medium text-indigo-600 hover:text-indigo-900"
                    onClick={() => setHelpStepIndex(helpStepIndex + 1)}
                  >
                    Next
                  </a>
                )}
                <a
                  className="mt-2 cursor-pointer text-sm font-medium text-gray-500 hover:text-gray-700"
                  onClick={handleClose}
                >
                  Dismiss
                </a>
              </div>
            </div>
            <div className="ml-4 flex flex-shrink-0">
              <button
                type="button"
                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={handleClose}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default forwardRef(SpotlightHelpInfo);
