import type { User } from "@sentry/nextjs";
import type { ActivityGroup } from "types/model/activity-group";
import type { Booking } from "types/model/booking";
import type { LineItem } from "types/model/line-item";

export enum DiscountRuleType {
  Code = "code",
  MultiAttendee = "multiAttendee",
  MultiPurchase = "multiPurchase"
}

export enum DiscountRuleUnit {
  FixedAmount = "fixedAmount",
  Percentage = "percentage",
  AmountAdditionalAttendee = "amountAdditionalAttendee",
  PercentageAdditionalAttendee = "percentageAdditionalAttendee",
  AmountPerSession = "amountPerSession"
}

export enum DiscountRuleSessionsOption {
  SameActivity = "sameActivity",
  MultipleActivities = "multipleActivities"
}

export enum DiscountCodeUsageType {
  AllActivities = "allActivities",
  SelectedActivities = "selectedActivities"
}

export interface MultiSessionCondition {
  _id?: string;
  minimumSessions: number;
  unit: DiscountRuleUnit;
  fixedAmount?: number;
  percentage?: number;
  amountPerSession?: number;
}

export interface MultiActivityCondition {
  _id?: string;
  minimumActivities: number;
  unit: DiscountRuleUnit;
  fixedAmount?: number;
  percentage?: number;
}

export enum MultiPurchaseDiscountRuleType {
  Session = "session",
  Activity = "activity"
}

export interface DiscountRuleFormData<AGs = ActivityGroup[]> {
  name?: string;
  code?: string;
  minimumTotal?: number;
  maximumDiscountAmount?: number;
  type?: DiscountRuleType;
  multiPurchaseType?: MultiPurchaseDiscountRuleType;
  sessionsOption?: DiscountRuleSessionsOption;
  unit?: DiscountRuleUnit;
  fixedAmount?: number;
  percentage?: number;
  amountAdditionalAttendee?: number;
  percentageAdditionalAttendee?: number;
  codeUsage?: DiscountCodeUsageType;
  activityGroups?: AGs;
  enabled?: boolean;
  multiSessionConditions?: MultiSessionCondition[];
  multiActivityConditions?: MultiActivityCondition[];
  dates?: {
    shouldLimitDatePeriod?: boolean;
    specifyStartDate?: boolean;
    startDate?: Date;
    specifyEndDate?: boolean;
    endDate?: Date;
  };
  redemptionLimit?: {
    shouldLimitUserRedemptions?: boolean;
    maxUserRedemptions?: string;
    shouldLimitRedemptions?: boolean;
    maxRedemptions?: string;
  };
  onlyAllowForUserFirstBooking?: boolean;
}

export interface DiscountRule<AG = ActivityGroup[]> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _id: any;
  name: string;
  activityGroups: AG;
  type: DiscountRuleType;
  multiPurchaseType?: MultiPurchaseDiscountRuleType;
  unit: DiscountRuleUnit;
  multiSessionConditions: MultiSessionCondition[];
  multiActivityConditions: MultiActivityCondition[];
  fixedAmount?: number;
  percentage?: number;
  amountAdditionalAttendee?: number;
  percentageAdditionalAttendee?: number;
  codeUsage?: DiscountCodeUsageType;
  amountPerSession?: number;
  minimumTotal?: number;
  minimumSessions?: number;
  maximumDiscountAmount?: number;
  onlyAllowForUserFirstBooking?: boolean;
  code?: string;
  sessionsOption?: DiscountRuleSessionsOption;
  shouldApplyGlobally?: boolean;
  startDate?: Date;
  endDate?: Date;
  maxRedemptions?: number;
  maxUserRedemptions?: number;
  enabled: boolean;
  client: string;
  timesUsed?: number;
  toObject: () => DiscountRule;
}

export interface DiscountLineItemToAdjust {
  lineItem: LineItem;
  amount: number;
}

export interface DiscountRuleUsageItem {
  _id: string;
  user_info: User;
  total: number;
  booking: Booking;
}

export interface DiscountRuleUsageData {
  items: DiscountRuleUsageItem[];
  totalUsageAmount: number;
}
